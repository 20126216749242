<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <!-- <FullCalendar :options="calendarOptions" /> -->
      </div>
    </div>
  </div>
</template>

<script>
// import "@fullcalendar/core/vdom"; // solves problem with Vite
// import FullCalendar from "@fullcalendar/vue";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import interactionPlugin from "@fullcalendar/interaction";

export default {
  data() {
    return {
    //   calendarOptions: {
    //     plugins: [dayGridPlugin, interactionPlugin],
    //     initialView: "dayGridMonth",
    //   },
    };
  },

  async created() {},
  async mounted() {},
  watch: {},
  methods: {},
  components: { 
    // FullCalendar 
    },
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
